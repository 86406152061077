body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.orange {
  color: rgb(255, 111, 0) !important;
}

.blue {
  color: blue !important;
}

.green {
  color: green !important;
}

a,
a:hover {
  text-decoration: none;
  transition: 0.3s ease all;
}

button {
  transition: 0.3s ease all;
}

.cursor-pointer {
  cursor: pointer;
}
.header-main {
  background: var(--bs-white);
  top: 0;
  left: 0;
  height: 80px;
  z-index: 9;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}

.user-header-custom.header-main {
  left: auto;
  right: 0;
  width: calc(100% - 386px);
  padding: 0 30px;
  height: 96px;
}

.user-header-custom h5 {
  color: #272b30;
  font-size: 28px;
  /* font-style: italic; */
  font-weight: 700;
}

.user-header-custom ul li.list-inline-item:not(:last-child) {
  margin-right: 35px;
}

.toggle-btn {
  position: absolute;
  left: 0;
  top: 0;
}

.back-arrow-btn {
  color: #262c2e;
  font-size: 20px;
  /* font-style: italic; */
  font-weight: 700;
}

.back-arrow-btn img {
  margin-right: 10px;
}
.logo-div .ant-btn {
  color: #000;
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 700;
}

.logo-div .ant-btn img {
  margin-right: 10px;
}

.header-dropdown-custom .custom-dropdown {
  height: 48px;
  width: 48px;
  border-radius: 0;
}

.main-content-fluid {
  margin-top: 80px;
  height: 203px;
  background: rgba(0, 0, 0, 0.8);
}

.main-content-fluid h5 {
  color: var(--bs-white);
  font-size: 29px;
  /* font-style: italic; */
  font-weight: 700;
  margin-bottom: 25px;
}

.custom-search-sub .ant-input-search .ant-input-group .ant-input {
  height: 50px;
  border: 0;
  box-shadow: none;
  width: 375px;
  color: #000000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
  border-radius: 8px 0 0 8px;
  padding: 0 20px;
}

.custom-search-sub
  .ant-input-search
  .ant-input-group
  .ant-input::-webkit-input-placeholder {
  color: #acacac;
}

.main-content-fluid
  .custom-search-sub
  .ant-input-search
  .ant-input-group
  .ant-input-group-addon
  .ant-input-search-button {
  height: 50px;
  border: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-search-sub .anticon.anticon-search {
  font-size: 22px;
  color: #acacac;
}

.ant-btn.location-map-btn {
  height: 52px;
}

.ant-btn.incident-group-btn {
  height: 50px;
}

/*main-section*/
.main-inner-fluid {
  background: #f2f6fb;
  padding: 45px 15px;
}

.main-inner-fluid.auths-details-main {
  background: linear-gradient(270deg, #4584e3 0%, #3cc19c 100%);
  /* margin-top: 80px; */
  height: 100vh;
  padding: 90px 15px;
}

.card-common-row {
  min-height: 468px;
  background: var(--bs-white);
  border-radius: 8px;
  padding: 30px 10px;
  filter: drop-shadow(0 10px 40px rgba(0, 0, 0, 0.07));
}

.card-common-row.card-common-row-small {
  min-height: 140px;
  padding: 10px 5px;
  margin-bottom: 25px;
}

.filter-card-left {
  width: 300px;
}

.details-card-right {
  width: calc(100% - 300px);
  height: 500px;
  overflow-y: scroll;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1205px;
  }
}

.card-common-row h5 {
  color: #000;
  font-size: 22px;
  /* font-style: italic; */
  font-weight: 700;
  margin-bottom: 20px;
}

.select-country {
  margin-bottom: 30px;
  height: 45px;
  width: auto;
}

.card-common-row .ant-select {
  width: 100%;
  margin-bottom: 15px;
  height: 50px;
}

.card-common-row .ant-select .ant-select-selector {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  color: #acacac;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
  padding: 0 25px;
}

.incident-heading-inner img {
  height: 66px;
  width: 66px;
}

.incident-heading-inner img.rating-icon-img {
  height: auto;
  width: auto;
}

.incident-heading-inner h6 {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-common-row p {
  color: #000;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 400;
}

.ant-btn.more-info-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.more-info-btn img {
  height: 18px;
  width: 4px;
}

.custom-footer-fluid {
  background: #262626;
  padding: 70px 0 105px;
}

.logo-footer {
  color: #fff;
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 700;
}

.logo-footer img {
  margin-right: 10px;
}

.footer-nav-links {
  margin: 45px 0;
}

.footer-nav-links li.list-inline-item:not(:last-child) {
  margin-right: 35px;
}

.footer-nav-links li a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.footer-bottom-card {
  bottom: 0;
  left: 0;
  height: 35px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  background: #414141;
}
.incident-btn-group {
  margin-bottom: 35px;
}

.incident-btn-group ul li.list-inline-item:not(:last-child) {
  margin-right: 10px;
}

.ant-btn.details-btn {
  background: var(--bs-white);
  height: 48px;
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 500;
  border: none;
  padding: 0 20px;
  border-radius: 4px;
}

.details-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000000;
}

.ant-btn.details-btn.active,
.details-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ).active:hover {
  color: #3cbba2;
}

.ant-btn.details-btn img {
  margin-right: 5px;
}

.card-common-row.incident-card-details {
  padding: 45px 50px;
}

.incident-detail-header {
  color: #000;
  font-size: 24px;
  /* font-style: italic; */
  font-weight: 700;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.incident-detail-header:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 75px;
  background: linear-gradient(180deg, #4584e3 0%, #3cc19c 100%);
}

.incident-inner-details {
  border-bottom: 1px dashed #acacac;
  position: relative;
  padding: 20px 10px;
}

.incident-inner-details:before {
  content: "";
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  background: linear-gradient(180deg, #4584e3 0%, #3cc19c 100%);
}

.incident-inner-details h5 {
  color: #000;
  font-size: 20px;
  /* font-style: italic; */
  font-weight: 700;
}

.incident-inner-details h6 {
  color: #000;
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 400;
  padding-bottom: 7px;
}

.incident-inner-details h5.primary-color {
  color: #3cbda0;
}

.go-back-dashboard-btn.ant-btn,
.go-back-dashboard-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 500;
  background: #4584e3;
  filter: drop-shadow(3px 6px 14px rgba(138, 49, 49, 0.19));
  height: 51px;
  border-color: #4584e3;
  width: 324px;
  margin-top: 45px;
}

.auths-main-card {
  background: var(--bs-white);
  width: 415px;
  border-radius: 18px;
  padding: 50px 45px;
}

.img-fluid.auths-logo {
  margin-bottom: 30px;
}

.auths-main-card h1 {
  color: #000;
  font-size: 34px;
  /* font-style: italic; */
  font-weight: 700;
  margin-bottom: 15px;
}

.auths-main-card p {
  color: #000;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 400;
  margin-bottom: 30px;
  padding: 0 5px;
}

.country-code-div {
  width: 65px;
  margin-right: 13px;
}

.mobile-number-div {
  width: calc(100% - 78px);
}

.auths-main-card .ant-form .ant-form-item .ant-input {
  height: 48px;
  background: #f5f5f5;
  border: none;
  color: #000000;
  padding: 0 17px;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
}

.auths-main-card
  .ant-form
  .ant-form-item
  .ant-input::-webkit-input-placeholder {
  color: #000000;
}

.auths-main-card .ant-form .ant-form-item {
  margin-bottom: 30px;
}

.auths-main-card .ant-form .ant-form-item .ant-btn {
  filter: drop-shadow(3px 6px 14px rgba(138, 49, 49, 0.19));
  width: 100%;
  height: 51px;
  background: #4584e3;
  color: #fff;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 500;
  border: none;
}

.continue-with-div h6 {
  margin: 25px 0 30px;
  color: #000;
  font-size: 12px;
  /* font-style: italic; */
  font-weight: 400;
}

.continue-with-div h6:before,
.continue-with-div h6:after {
  content: "";
  position: absolute;
  top: 30px;
  width: 30%;
  height: 4px;
  background: linear-gradient(
    135deg,
    #3dbca2 0%,
    #3fb2af 58.3%,
    #48cbd6 86.2%,
    #5bc7d4 100%
  );
}

.continue-with-div h6:before {
  left: 15px;
}

.continue-with-div h6:after {
  right: 15px;
}

.continue-with-div ul li .ant-btn {
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.03));
  height: 50px;
  width: 90px;
  border: none;
}

.resend-code {
  font-size: 14px;
  font-style: italic;
  margin: 15px 0 0;
  font-weight: 400;
}

.resend-code a {
  color: #000;
  text-decoration-line: underline;
}

.auths-main-card.auths-main-card-training {
  width: 396px;
}

.auths-main-card-training h5 {
  margin: 10px 0 20px;
  color: #000;
  font-size: 19px;
  /* font-style: italic; */
  font-weight: 700;
}

.auths-main-card-training .list-inline-item:not(:last-child) {
  margin-right: 30px;
}

.auths-main-card-training .ant-btn,
.auths-main-card-training
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  height: 51px;
  filter: drop-shadow(3px 6px 14px rgba(138, 49, 49, 0.19));
  border-color: #979797;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 500;
  background: var(--bs-white);
  width: 106px;
}

.auths-main-card-training .ant-btn.primary-btn,
.auths-main-card-training
  .primary-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--bs-white);
  background: linear-gradient(#4584e3, #3cc19c);
  border: none;
}

.card-common-row.training-card {
  padding: 12px 0;
  min-height: 105px;
}

.training-details-div {
  padding-left: 10px;
}

.training-card .ant-btn.more-info-btn {
  position: absolute;
  top: -10px;
  right: 15px;
}

/*SIDEBAR*/
.close-btn.ant-btn {
  top: 10px;
  right: 15px;
}

.sidebar-custom-main {
  z-index: 91;
  top: 0;
  left: -1000%;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.78); */
  width: 100vw;
  transition: 0.4s ease all;
}

.sidebar-custom-main.sidebar-active {
  left: 0;
}

.sidebar-custom-main-inner {
  width: 400px;
  background: var(--bs-white);
  box-shadow: 16px 0px 100px 0px #575555;
  height: 100%;
  padding: 30px;
}

.logo-tag,
.logo-tag:hover {
  color: #000;
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 700;
}

.logo-tag img {
  margin-right: 10px;
}

.sidebar-list {
  margin-top: 25px;
}

.sidebar-list li a {
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
  border-bottom: 1px solid rgba(65, 158, 196, 0.13);
  padding: 20px 0;
}

.sidebar-list li a img {
  margin-right: 10px;
}

.main-content-fluid.main-content-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../images/banner-img-main.png") no-repeat center;
  background-size: cover;
}

.banner-heading h1 {
  color: var(--bs-white);
  font-size: 29px;
  /* font-style: italic; */
  font-weight: 700;
}

.gallery-img-row-card {
  background: var(--bs-white);
  border-radius: 8px;
  padding: 30px 15px 6px;
}

.inner-gallery-card {
  width: 100%;
  background: #bcbaba;
  height: 211px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.ant-form.common-form-main .ant-form-item {
  margin-bottom: 15px;
}

.ant-form.common-form-main .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form.common-form-main .ant-form-item-label label,
.ant-form.common-form-main .ant-radio-wrapper {
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
}

.ant-form.common-form-main .ant-radio-wrapper .ant-radio .ant-radio-inner {
  width: 18px;
  height: 18px;
  border: 2px dashed #448ed8;
  background: #ebebeb;
}

.ant-form.common-form-main
  .ant-radio-wrapper
  .ant-radio.ant-radio-checked
  .ant-radio-inner {
  background: #448ed8;
}

.ant-form.common-form-main .ant-select {
  height: 48px;
}

.ant-form.common-form-main .ant-form-item .ant-input,
.ant-form.common-form-main
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-form.common-form-main
  .ant-form-item
  .ant-upload-wrapper
  .ant-upload
  .ant-btn {
  border: 1px solid #d6d6d6;
  height: 48px;
  border-radius: 6px;
  padding: 10px 15px;
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
  width: 100%;
}

.ant-form.common-form-main .ant-form-item .ant-upload-wrapper .ant-upload {
  width: 100%;
}

.ant-form.common-form-main
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 100%;
}

.ant-form.common-form-main .ant-form-item .ant-input.textArea-custom {
  height: 127px;
}

.ant-form.common-form-main .ant-form-item .ant-input.textArea-custom-contact {
  height: 173px;
}

.ant-form.common-form-main .ant-form-item .ant-input.textArea-custom-support {
  height: 110px;
}

.ant-form.common-form-main
  .ant-form-item
  .ant-input::-webkit-input-placeholder {
  color: #000;
}

.choose-categories-section {
  width: 378px;
  margin: auto;
}

.choose-categories-section .auths-main-card.auths-main-card-training {
  width: 100%;
  height: 176px;
  border-radius: 6px;
  padding: 0;
  margin: 12px 0;
}

.choose-categories-section .auths-main-card-training h5 {
  font-size: 18px;
}

.user-sidebar-custom {
  left: 0;
  top: 0;
  z-index: 1;
  width: 386px;
  height: 100vh;
  padding: 45px 30px;
  background: linear-gradient(270deg, #4584e3 0%, #3cc19c 100%);
}

.user-profile-details {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.user-img-div img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.user-details-div h5 {
  color: var(--bs-white);
  font-size: 20px;
  /* font-style: italic; */
  font-weight: 500;
  margin-bottom: 3px;
}

.user-details-div p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.user-nav-details {
  padding-top: 45px;
  height: calc(100vh - 150px);
  overflow: auto;
}

.user-nav-details h6 {
  color: var(--bs-white);
  font-size: 12px;
  /* font-style: italic; */
  font-weight: 400;
  margin-bottom: 30px;
}

.user-nav-details .ant-collapse {
  border: 0;
  border-radius: 0;
  background: transparent;
}

.user-nav-details .ant-collapse .ant-collapse-item {
  border-radius: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

.user-nav-details ul li a,
.user-nav-details .ant-collapse .ant-collapse-item .ant-collapse-header {
  color: var(--bs-white);
  font-size: 15px;
  /* font-style: italic; */
  font-weight: 400;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  display: inline-block;
  padding: 15px 0;
}

.user-nav-details .ant-collapse .ant-collapse-item .ant-collapse-header {
  border-radius: 0;
  border: 0;
}

.user-nav-details .ant-collapse .ant-collapse-item .ant-collapse-expand-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(90deg);
  transition: 0.4s ease all;
}

.user-nav-details
  .ant-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-expand-icon {
  transform: rotate(180deg);
}

.user-nav-details
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-expand-icon
  .anticon {
  font-size: 18px;
}

.user-nav-details .ant-collapse .ant-collapse-item .ant-collapse-content {
  background: transparent;
  border-radius: 0;
  border: 0;
}

.user-nav-details
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  padding: 0 15px 7px;
}

.user-nav-details
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box
  a {
  border: 0;
  padding: 8px 0;
}

.main-user-content {
  background: #f2f6fb;
  padding: 60px;
  width: calc(100% - 386px);
  float: right;
  margin-top: 96px;
  height: calc(100vh - 96px);
  overflow: auto;
}

.assigned-incident-card {
  min-height: 118px;
  background: var(--bs-white);
  filter: drop-shadow(0px 25px 40px rgba(0, 0, 0, 0.03));
  border-radius: 6px;
  padding: 20px 5px;
  margin-bottom: 24px;
}
.close-btn.close-btn-profile {
  display: none;
}
.assigned-incident-card h6 {
  color: #000;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: 500;
  margin-bottom: 5px;
}

.assigned-incident-card p {
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 500;
  margin-top: 7px;
}

.site-calendar-customize-header-wrapper {
  width: 100%;
  border: none;
  border-radius: 0;
}

.site-calendar-customize-header-wrapper .ant-picker-calendar {
  border-radius: 0;
}

.custom-calendar-inner {
  padding: 20px 15px 15px;
}

.schedule-details h5 {
  color: #000;
  font-size: 24px;
  /* font-style: italic; */
  font-weight: 400;
  margin-bottom: 20px;
}

.schedule-card-primary {
  background: #54cbb0;
  min-height: 75px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.calendar-icon-div {
  width: 49px;
  height: 49px;
  float: left;
}

.calendar-icon-div img {
  width: 100%;
  height: 100%;
}

.schedule-details-div {
  float: left;
  width: calc(100% - 49px);
  padding-left: 15px;
}

.schedule-details-div h6 {
  color: var(--bs-white);
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 500;
  margin-bottom: 5px;
}

.schedule-details-div ul li {
  color: var(--bs-white);
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
}

.schedule-details-div ul li img {
  padding-right: 5px;
}

.preferences-ul {
}

.preferences-ul li {
  height: 60px;
  background: var(--bs-white);
  margin-bottom: 20px;
  color: #000;
  font-size: 17px;
  /* font-style: italic; */
  font-weight: 500;
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.switch-btn-div {
  right: 20px;
}

.ant-tabs.custom-tab .ant-tabs-nav {
  margin-bottom: 30px;
}

.ant-tabs.custom-tab .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs.custom-tab .ant-tabs-nav-list .ant-tabs-tab {
  height: 50px;
  border: 1px solid #000000;
  color: #000;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 500;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
}

.custom-tab.ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  border-radius: 0 8px 8px 0;
  margin: 0;
}

.ant-tabs.custom-tab .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: linear-gradient(180deg, #4584e3 0%, #3cc19c 100%);
  border: none;
}

.ant-tabs.custom-tab
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--bs-white);
}
.custom-collapse-div .ant-collapse {
  border: 0;
  background: transparent;
}

.custom-collapse-div .ant-collapse .ant-collapse-item {
  background: var(--bs-white);
  border-radius: 6px;
  margin-bottom: 15px;
  border: 0;
}
.custom-collapse-div
  .ant-collapse
  .ant-collapse-item
  .ant-collapse-expand-icon {
  display: none;
}
.custom-collapse-div .ant-collapse .ant-collapse-item .ant-collapse-header {
  color: #000;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 500;
  padding: 14px 15px;
}

.custom-collapse-div .ant-collapse .ant-collapse-content {
  border: 0;
}

.custom-collapse-div
  .ant-collapse
  .ant-collapse-content
  .ant-collapse-content-box {
  padding-top: 0;
}
.expand-icon-div {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-icon-div img {
  transition: 0.3s ease all;
}
.expand-icon-div img.minus-icon {
  opacity: 0;
  position: absolute;
}
.custom-collapse-div
  .ant-collapse-item.ant-collapse-item-active
  .expand-icon-div
  img.plus-icon {
  opacity: 0;
}
.custom-collapse-div
  .ant-collapse-item.ant-collapse-item-active
  .expand-icon-div
  img.minus-icon {
  opacity: 1;
}
.custom-collapse-div .ant-collapse p {
  color: #7c7c7c;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
  margin-bottom: 5px;
}
.custom-collapse-div .ant-collapse .ant-collapse-content-box > span {
  color: #a5a5a5;
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 400;
}
.custom-tab-certificate .ant-tabs-nav {
  margin-bottom: 24px;
}
.custom-tab-certificate .ant-tabs-nav:before {
  display: none;
}
.custom-tab-certificate .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 140px;
  height: 40px;
  background: #eeeeee;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}
.custom-tab-certificate .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  color: #000;
  font-size: 15px;
  /* font-style: italic; */
  font-weight: 400;
}
.custom-tab-certificate .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: #41a2bf;
}
.custom-tab-certificate
  .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--bs-white);
}
.custom-tab-certificate.ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 11px;
}
.add-certificate-btn.ant-btn,
.add-certificate-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  height: 44px;
  border: 1px solid #4585e2;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 700;
  background: transparent;
  padding: 0 25px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.add-certificate-btn.ant-btn img {
  margin-right: 10px;
}
.toggle-btn-mobile {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  display: none;
  position: absolute;
  left: -15px;
  top: 0;
}
.toggle-btn-mobile img {
  width: 60px;
  height: 60px;
}

.hide-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
