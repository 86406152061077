@media only screen and (min-width: 320px) and (max-width: 767px) {
  .header-main {
    padding-left: 60px;
    height: 60px;
  }
  .back-arrow-btn {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .logo-div .ant-btn {
    font-size: 14px;
  }
  .logo-div .ant-btn img {
    margin-right: 10px;
    height: 40px;
  }
  .header-dropdown-custom .custom-dropdown {
    height: 40px;
    width: 40px;
  }
  .filter-card-left {
    width: 100%;
    margin-bottom: 24px;
  }
  .main-inner-fluid {
    padding: 30px 0;
  }
  .custom-search-sub .ant-input-search .ant-input-group .ant-input {
    width: 150px;
    height: 46px;
    padding: 0 15px;
  }
  .toggle-btn img {
    width: 60px;
    height: 60px;
  }
  .toggle-with-back-arrow {
    width: 90px !important;
    padding-right: 0;
  }
  .logo-div {
    width: 140px !important;
    padding: 0;
  }
  .header-dropdown-custom {
    padding-left: 0;
  }
  .main-content-fluid {
    margin-top: 60px;
    height: 130px;
  }
  .main-content-fluid h5 {
    font-size: 22px;
    margin-bottom: 14px;
  }
  .main-content-fluid
    .custom-search-sub
    .ant-input-search
    .ant-input-group
    .ant-input-group-addon
    .ant-input-search-button {
    height: 46px;
    width: 46px;
  }
  .ant-btn.incident-group-btn {
    height: 46px;
  }
  .ant-btn.location-map-btn {
    height: 48px;
  }
  .card-common-row {
    min-height: auto;
    padding: 20px 10px 5px;
  }
  .details-card-right {
    width: 100%;
    height: 500px
  }
  .card-common-row.card-common-row-small {
    min-height: 130px;
    padding: 10px 5px 15px;
    margin-bottom: 15px;
  }
  .incident-heading-inner h6 {
    font-size: 16px;
  }
  .card-common-row p {
    font-size: 14px;
  }
  .custom-footer-fluid {
    padding: 30px 0 60px;
  }
  .footer-nav-links {
    margin: 25px 0;
  }
  .footer-nav-links li.list-inline-item:not(:last-child) {
    margin-right: 15px;
  }
  .footer-nav-links li a {
    font-size: 13px;
  }
  .incident-btn-group ul {
    margin-bottom: 20px;
    display: inline-block !important;
  }
  .card-common-row.incident-card-details {
    padding: 30px 15px;
    margin: 0;
  }
  .incident-detail-header {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .incident-inner-details {
    padding: 15px 5px;
  }
  .incident-inner-details h6 {
    font-size: 15px;
  }
  .incident-inner-details h5 {
    font-size: 18px;
  }
  .go-back-dashboard-btn.ant-btn,
  .go-back-dashboard-btn.ant-btn-default:not(:disabled):not(
      .ant-btn-disabled
    ):hover {
    font-size: 12px;
    height: 45px;
    width: 100%;
    margin-top: 30px;
  }
  .main-inner-fluid.auths-details-main {
    margin-top: 60px;
    padding: 45px 15px;
  }
  .auths-main-card {
    width: 100%;
    padding: 30px;
  }
  .auths-main-card h1 {
    font-size: 28px;
  }
  .auths-main-card p {
    font-size: 14px;
  }
  .auths-main-card .ant-form .ant-form-item .ant-btn {
    height: 45px;
    font-size: 14px;
  }
  .continue-with-div h6 {
    margin: 18px 0 20px;
  }
  .continue-with-div h6:before,
  .continue-with-div h6:after {
    top: 23px;
    width: 26%;
  }
  .continue-with-div ul li .ant-btn {
    height: 50px;
    width: 70px;
  }
  .gallery-img-row-card {
    padding: 24px 15px 6px;
    margin: 0;
  }
  .inner-gallery-card {
    height: 150px;
  }
  .user-sidebar-custom {
    width: 100%;
    height: 100vh;
    padding: 30px 15px;
    left: -1000%;
    transition: 0.4s ease all;
    z-index: 10;
  }
  .user-sidebar-custom.profile-sidebar-active {
    left: 0;
  }
  .user-header-custom.header-main {
    width: 100%;
    padding: 0 15px;
    height: 60px;
  }
  .main-user-content {
    padding: 30px;
    width: 100%;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .user-header-custom h5 {
    font-size: 16px;
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 40px;
  }
  .user-header-custom ul li.list-inline-item:not(:last-child) {
    margin-right: 15px;
  }
  .assigned-incident-card {
    min-height: 102px;
    padding: 15px 5px;
    margin-bottom: 15px;
  }
  .schedule-details h5 {
    font-size: 18px;
    margin: 20px 0;
  }
  .preferences-ul li span {
    width: 80%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .main-user-content.certificate-user-content {
    padding-top: 65px;
  }
  .add-certificate-btn.ant-btn,
  .add-certificate-btn.ant-btn-default:not(:disabled):not(
      .ant-btn-disabled
    ):hover {
    height: 40px;
    font-size: 14px;
    padding: 0 10px;
    top: -50px;
  }
  .custom-tab-certificate .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
    width: 100px;
  }
  .toggle-btn-mobile {
    display: block;
  }
  .incident-btn-group ul li.list-inline-item:not(:last-child) {
    margin-right: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .close-btn.close-btn-profile {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .incident-btn-group ul {
    display: inline-block !important;
  }
  .incident-btn-group ul li.list-inline-item:not(:last-child) {
    margin-right: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .auths-main-card {
    width: fit-content;
    padding: 35px 45px;
  }
  .toggle-with-back-arrow {
    padding-left: 60px;
  }
  .filter-card-left {
    width: 250px;
  }
  .details-card-right {
    width: calc(100% - 250px);
    height: 500px
  }
  .main-inner-fluid.auths-details-main {
    height: calc(100vh - 80px);
  }
  .user-sidebar-custom {
    width: 250px;
    padding: 45px 20px;
  }
  .user-header-custom.header-main {
    width: calc(100% - 250px);
    padding: 0 15px;
  }
  .main-user-content {
    padding: 45px 30px;
    width: calc(100% - 250px);
  }
  .assigned-incident-card h6 {
    font-size: 14px;
  }
  .assigned-incident-card {
    min-height: 100px;
  }
  .user-header-custom h5 {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
  }
  .schedule-details h5 {
    margin-top: 30px;
  }
  .main-content-fluid h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .custom-search-sub .ant-input-search .ant-input-group .ant-input {
    width: 300px;
  }
  .main-content-fluid {
    height: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .user-sidebar-custom {
    width: 350px;
    padding: 45px 30px;
  }
  .user-header-custom h5 {
    width: auto;
  }
  .user-header-custom.header-main {
    width: calc(100% - 350px);
    padding: 0 30px;
  }
  .main-user-content {
    padding: 60px;
    width: calc(100% - 350px);
  }
  .assigned-incident-card h6 {
    font-size: 17px;
  }
  .schedule-details h5 {
    margin-top: 0;
  }
}
